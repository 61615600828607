<template>
  <div id="contents" class="notice">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="openTabIndex"
          :commit="'setOpenTabIndexToNoticePage'"
          :options="[
            { title: '조회&수정', name: 'modification' },
            { title: '등록', name: 'new' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            class="btn_admin"
            :class="managementMode ? 'on' : ''"
            @click="toggleManagement"
            v-show="openTabIndex == 0"
            href="#"
            >관리</a
          >
          <!-- <button class="btn_admin">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            :class="index == openTabIndex ? 'active' : ''"
            v-for="(subTab, index) in subTabs"
            :key="subTab.name"
          >
            <a @click="setOpenTabIndex(index)">{{ subTab.title }}</a>
          </li>
        </ul>
      </div>
      <notice-modification-form
        v-if="openTabIndex == 0"
      ></notice-modification-form>
      <notice-new-form v-if="openTabIndex == 1"></notice-new-form>
    </div>
  </div>
</template>

<script>
import NoticeModificationForm from '@/views/forms/System/NoticeModificationForm.vue';
import NoticeNewForm from '@/views/forms/System/NoticeNewForm.vue';
import { mapGetters, mapMutations } from 'vuex';
import ClosePageMixin from '@/mixins/closePage';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import FavoriteMixin from '@/mixins/favorite';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    NoticeModificationForm,
    NoticeNewForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      subTabs: 'getSubTabsFromNoticePage',
      openTabIndex: 'getOpenTabIndexFromNoticePage',
      managementMode: 'getManagementModeFromNoticePage',
    }),
  },
  methods: {
    ...mapMutations({
      setOpenTabIndex: 'setOpenTabIndexToNoticePage',
      toggleManagementMode: 'toggleManagementModeToNoticePage',
    }),
    toggleManagement() {
      if (this.selectedIndex != -1) {
        this.toggleManagementMode();
      }
    },
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitNoticePage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
