<template>
  <div class="article">
    <div class="tbl_wrap notice_list">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>제목</th>
              <th>작성자</th>
              <th>등록시간</th>
              <th>만료일</th>
              <th v-if="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(notice, index) in notices"
              :key="notice.id"
              :class="selectedIndex == index ? 'active' : ''"
              @click="
                setSelectedIndex(index);
                error_expired_day = false;
              "
            >
              <td>{{ notice.title }}</td>
              <td>{{ getUserName(notice.user_id) }}</td>
              <td>{{ notice.create_time | formatDate }}</td>
              <td>{{ notice.expired_day || '' }}</td>
              <td v-if="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal(index)">
                  delete
                </button>
              </td>
            </tr>
            <tr
              @click="
                setSelectedIndex(-1);
                error_expired_day = false;
              "
              v-if="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
            >
              <td colspan="5"><i class="fa-plus"></i>신규 공지사항 입력</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="form">
        <form @submit.prevent>
          <div class="input_text">
            <label class="require">만료일</label>
            <input
              :disabled="!managementMode"
              :readonly="!managementMode"
              type="date"
              :value="managementData.expired_day"
              @change="changeExpiredDay($event)"
            />
          </div>
          <div class="warning" v-show="error_expired_day">
            만료일은 오늘 이전으로 설정할 수 없습니다.
          </div>
          <div class="input_text">
            <label class="require">제목</label>
            <input
              :disabled="!managementMode"
              type="text"
              placeholder="제목"
              v-model="managementData.title"
            />
          </div>
          <div class="input_text">
            <label>내용</label>
            <textarea
              :disabled="!managementMode"
              :class="{ active: !managementMode }"
              placeholder="내용"
              v-model="managementData.detail"
            ></textarea>
          </div>
          <button
            class="btn_sub2"
            v-show="managementMode"
            :disabled="isValidModify"
            @click="submitForm()"
          >
            {{ selectedIndex == -1 ? '등록' : '수정' }}
          </button>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_notice($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { mapGetters, mapMutations } from 'vuex';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      //error
      error_expired_day: false,
    };
  },
  computed: {
    ...mapGetters({
      notices: 'getNoticeList',
      managementData: 'getManagementDataFromNoticePage',
      selectedIndex: 'getSelectedIndexFromNoticePage',
      managementMode: 'getManagementModeFromNoticePage',
      users: 'getUsersTempFromUserPage',
      myAccount: 'getUserId',
    }),
    isValidModify() {
      if (this.managementMode && this.selectedIndex != -1) {
        const modifyData = JSON.stringify(this.managementData);
        const originData = JSON.stringify(this.notices[this.selectedIndex]);
        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
  },
  methods: {
    ...mapMutations({
      setSelectedIndex: 'setSelectedIndexToNoticePage',
      toggleManagementMode: 'toggleManagementModeToNoticePage',
    }),
    changeExpiredDay(e) {
      const today = yyyymmdd(new Date());
      if (new Date(today) > new Date(e.target.value)) {
        e.target.value = today;
        this.managementData.expired_day = today;
        this.error_expired_day = true;
      } else {
        this.managementData.expired_day = e.target.value;
        this.error_expired_day = false;
      }
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },

    async fetch_notice_list() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_NOTICE_LIST')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '공지사항 리스트를 불러오는 중 오류발생.',
          );
        });
      this.hideSpinner();
    },
    async fetch_user_list() {
      try {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_USER_LIST')
          .then(() => {})
          .catch(() => {
            this.openOneButtonModal(
              '불러오기 오류',
              '사용자 정보를 불러오는 중 오류발생.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '사용자 정보를 불러오는 중 오류발생.',
        );
        this.hideSpinner();
      }
    },
    getUserName(id) {
      const element = this.users.find(x => x.id == id);
      if (element != null || element != undefined) {
        return element.name;
      } else {
        return '';
      }
    },
    getUserAccount(id) {
      const element = this.users.find(x => x.id == id);
      if (element != null || element != undefined) {
        return element.account;
      } else {
        return '';
      }
    },
    async submitForm() {
      if (
        this.managementData.title == null ||
        this.managementData.title == undefined ||
        this.managementData.title == ''
      ) {
        this.openOneButtonModal(
          '제목을 입력하세요',
          '제목을 반드시 입력하여주십시오.',
        );
        return;
      }

      try {
        if (
          this.managementData.id == -1 ||
          this.managementData.id == undefined
        ) {
          //INSERT
          console.log('insert');
          this.showSpinner();
          this.$store
            .dispatch('INSERT_NOTICE', this.managementData)
            .then(() => {
              this.toggleManagementMode();
              this.fetch_notice_list();
              this.setSelectedIndex(-1);
              this.error_expired_day = false;
            })
            .catch(() => {
              this.openOneButtonModal(
                '등록 오류',
                '공지사항 등록 중 오류가 발생하였습니다.',
              );
            });
          this.hideSpinner();
        } else {
          //UPDATE
          console.log('update');
          // const userId = this.managementData.user_id;

          this.showSpinner();
          this.$store
            .dispatch('UPDATE_NOTICE', this.managementData)
            .then(() => {
              this.toggleManagementMode();
              this.fetch_notice_list();
              this.setSelectedIndex(-1);
              this.error_expired_day = false;
            })
            .catch(error => {
              console.log(error);
              this.openOneButtonModal(
                '수정 오류',
                '공지사항 수정 중 오류가 발생하였습니다.',
              );
            });
          this.hideSpinner();
        }
      } catch (error) {
        console.log(error);
        this.openOneButtonModal(
          '오류',
          '공지사항 등록 또는 수정 중 오류가 발생하였습니다.',
        );
        this.hideSpinner();
      }
    },
    async delete_notice(arg1) {
      this.CloseModal();
      if (this.notices[arg1] == null || undefined) {
        return;
      }
      const userId = this.notices[arg1].user_id;
      if (this.getUserAccount(userId) != this.myAccount) {
        this.openOneButtonModal(
          '삭제 불가',
          '본인이 작성한 공지사항만 삭제할 수 있습니다.',
        );
        return;
      }
      try {
        this.showSpinner();
        this.$store
          .dispatch('DELETE_NOTICE', this.notices[arg1].id)
          .then(() => {
            this.fetch_notice_list();
            this.setSelectedIndex(-1);
          })
          .catch(() => {
            this.openOneButtonModal(
              '삭제 오류',
              '공지사항을 삭제 중 오류가 발생하였습니다.',
            );
          });
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.openOneButtonModal(
          '삭제 오류',
          '공지사항을 삭제 중 오류가 발생하였습니다.',
        );
        this.hideSpinner();
      }
    },
  },
  async created() {
    if (this.users.length < 1) {
      await this.fetch_user_list();
    }
    if (this.notices.length < 1) {
      await this.fetch_notice_list();
    }
  },
};
</script>

<style scoped></style>
