<template>
  <div class="article">
    <div class="aside_management_mode notice_enrollment_mode">
      <div class="form">
        <form @submit.prevent>
          <div class="input_text">
            <label class="require">만료일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="newData.expired_day"
              @change="changeExpiredDay($event)"
            />
          </div>
          <div class="warning" v-show="error_expired_day">
            만료일은 오늘 이전으로 설정할 수 없습니다.
          </div>
          <div class="input_text">
            <label class="require">제목</label>
            <input
              type="text"
              placeholder="제목을 입력하세요."
              v-model="newData.title"
            />
          </div>
          <div class="input_text">
            <label>내용</label>
            <textarea
              placeholder="내용을 입력하세요."
              v-model="newData.detail"
            ></textarea>
          </div>
          <button class="btn_sub2" @click="submitForm">등록</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { yyyymmdd } from '@/utils/func';
export default {
  data() {
    return {
      error_expired_day: false,
    };
  },
  mixins: [ModalMixin, SpinnerMixin],
  computed: {
    ...mapGetters({
      newData: 'getNewDataFromNoticePage',
    }),
  },
  methods: {
    ...mapActions(['INSERT_NOTICE', 'FETCH_NOTICE_LIST']),
    ...mapMutations(['clearNewDataToNoticePage']),
    changeExpiredDay(e) {
      const today = yyyymmdd(new Date());
      if (new Date(today) > new Date(e.target.value)) {
        e.target.value = today;
        this.newData.expired_day = today;
        this.error_expired_day = true;
      } else {
        this.newData.expired_day = e.target.value;
        this.error_expired_day = false;
      }
    },
    submitForm() {
      if (
        this.newData.title == '' ||
        this.newData.title == undefined ||
        this.newData.title == null
      ) {
        this.openOneButtonModal(
          '제목을 입력하세요',
          '제목을 반드시 입력하여주십시오.',
        );
        return;
      }
      this.insert_notice();
    },
    async insert_notice() {
      try {
        this.showSpinner();
        const message = await this.INSERT_NOTICE(this.newData);
        if (message != 'success') {
          this.openOneButtonModal(
            '등록 오류',
            '공지사항을 등록하는 중 오류가 발생하였습니다. 다시 로그인해주십시오.',
          );
        } else {
          this.clearNewDataToNoticePage();
          this.openOneButtonModal(
            '등록 성공',
            '공지사항을 성공적으로 등록하였습니다.',
          );
          this.fetch_notice_list();
          this.$store.commit('setSelectedIndexToNoticePage', -1);
        }
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.openOneButtonModal(
          '등록 오류',
          '공지사항을 등록하는 중 오류가 발생하였습니다. 다시 로그인해주십시오.',
        );
        this.hideSpinner();
      }
    },
    async fetch_notice_list() {
      try {
        this.showSpinner();
        const message = await this.FETCH_NOTICE_LIST();
        if (message != 'success') {
          this.openOneButtonModal(
            '불러오기 오류',
            '공지사항 리스트를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
          );
        }
        this.hideSpinner();
      } catch (error) {
        this.openOneButtonModal(
          '불러오기 오류',
          '공지사항 리스트를 불러오는 중 오류발생. 다시 로그인하여주십시오.',
        );
        this.hideSpinner();
      }
    },
  },
  async created() {
    if (this.newData.expired_day == null) {
      const date = new Date();
      this.newData.expired_day = yyyymmdd(date);
    }
  },
};
</script>

<style scoped></style>
